import React from 'react';
import styled from '@mui/material/styles/styled';
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import MuiPopper from '@mui/material/Popper';
import FormControl from '@mui/material/FormControl';
import { useField } from 'formik';
import { useAppState } from 'src/Context';

import Label from '../Label';
import Error from '../Error';
import { ListboxComponent } from './ListboxComponent';

type StyledAutocompleteProps = AutocompleteProps<string[], boolean, boolean, boolean>;

const Input = styled(MuiAutocomplete, {
  label: 'Input',
})<StyledAutocompleteProps>(() => {
  return {
    height: '38px',
    borderRadius: '5px',
    '& .MuiInputBase-root': {
      fontSize: '16px',
      lineHeight: '17.52px',
      color: '#343A40',
      backgroundColor: '#fff',
      padding: '0 10px 0 5px !important',
      minHeight: '38px',
      border: 'none',
      borderRadius: '5px',

      '& input': {
        height: '38px',
        padding: 0,
      },

      '& .MuiAutocomplete-input': {
        padding: 0,
      },

      '& fieldset': {
        border: 'none',
      },

      '& .MuiAutocomplete-endAdornment': {
        right: 'auto !important',
        left: '0.9rem',
      },

      '& .MuiChip-root': {
        fontSize: '1.4rem',
        lineHeight: '1.7rem',
        color: '#000',
        backgroundColor: '#F5F5F5',
        border: '1px solid #D9D9D9',
        borderRadius: '2px',
        padding: '0.4rem 0.8rem',
        margin: '0.4rem 0 0.4rem 0.8rem',

        '& .MuiChip-label': {
          padding: 0,
        },

        '& .MuiChip-deleteIcon': {
          color: '#000',
          opacity: 0.45,

          path: {
            transformOrigin: 'center',
            transform: 'scale(0.65)',
          },

          '&:hover': {
            opacity: 1,
          },
        },
      },
    },

    '& .MuiAutocomplete-popupIndicator': {
      marginRight: '0px',
      marginLeft: '-5px',
      color: '#000',
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      color: 'transparent',
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: '#000',
    },
  };
}) as typeof MuiAutocomplete;

const Popper = styled(MuiPopper, { label: 'CustomPopper' })(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: '8px',
    borderRadius: '0.5rem',
    backgroundColor: '#fff',
    border: '1px solid #063682',

    '& .MuiListSubheader-root': {
      fontSize: '1.2rem',
      lineHeight: '2.2rem',
      color: '#495057',
      backgroundColor: '#DEE2E6',
      padding: '0rem 0.8rem',
      margin: '0 0.8rem',
    },
    '& .MuiAutocomplete-noOptions': {
      color: '#343A40',
    },
    '& .MuiAutocomplete-option': {
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
      color: '#343A40',
      paddingRight: '0.8rem',
      margin: '0 0.8rem',

      '& .MuiCheckbox-root': {
        padding: 0,
        margin: '-0.1rem -0.6rem 0 0.8rem',
        backgroundColor: 'transparent',

        '& .MuiSvgIcon-root': {
          color: '#D9D9D9',
        },

        '&.Mui-checked .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },

        '& .MuiTouchRipple-root': {
          display: 'none',
        },

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },

      '&:hover': {
        backgroundColor: '#F1F3F5 !important',
      },

      '&.multiselect.MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&.multiselect.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label: string;
  isVirtualList?: boolean;
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: Props<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { name, label, isVirtualList = false, ...autocompleteInputProps } = props;
  const [, meta, helpers] = useField(name);
  const shouldRenderError = !!(meta.touched && meta.error);
  const { config } = useAppState();
  const { clientForm: texts } = config.texts;

  const onChange = (event: React.BaseSyntheticEvent, value: any) => {
    helpers.setValue(value);
  };

  return (
    <FormControl sx={{ mb: '19px' }} variant="outlined" fullWidth>
      <Label>{label}</Label>

      {isVirtualList ? (
        <Input
          {...autocompleteInputProps}
          PopperComponent={Popper}
          ListboxComponent={ListboxComponent}
          renderOption={(props, option, state) => {
            return [props, option, state] as React.ReactNode;
          }}
          sx={{ boxShadow: shouldRenderError ? `0 0 0 1px #FF4D4F` : `0 0 0 1px #063682` }}
          onChange={onChange}
          noOptionsText={texts.noOptionsText}
        />
      ) : (
        <Input
          {...autocompleteInputProps}
          PopperComponent={Popper}
          onChange={onChange}
          sx={{ boxShadow: shouldRenderError ? `0 0 0 1px #FF4D4F` : `0 0 0 1px #063682` }}
          noOptionsText={texts.noOptionsText}
        />
      )}
      {shouldRenderError && <Error error={meta.error as string | string[]} />}
    </FormControl>
  );
};

export default Autocomplete;
