import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAppState } from 'src/Context';
import { uploadPhoto } from 'src/api';

const Container = styled('label', { label: 'PhotoUploaderSlot' })<{ isError: boolean; state: SlotState }>(
  ({ state, theme, isError }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '187px',
    border: '1px dashed #FFFFFF',
    borderRadius: '2px',
    backgroundColor: 'rgba(255,255,255, 0.2)',
    cursor: 'pointer',
    ...(state === 'success' && {
      backgroundColor: 'transparent',
      border: '1px solid #FFFFFF',
      pointerEvents: 'none',
    }),
    ...((state === 'error' || isError) && {
      cursor: 'pointer',
      border: '1px dashed #FFADAF',
      backgroundColor: 'transparent',
    }),

    '& input': {
      display: 'none',
    },
  }),
);

const PlusIcon = styled('div', { label: 'PlusIcon' })<{ color: string; type: 'normal' | 'error' }>(
  ({ color, type }) => ({
    position: 'relative',
    width: '11.25px',
    height: '11.25px',
    margin: type === 'normal' ? '0 auto 9.37px' : '9px auto 9px',

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '2px',
      borderRadius: '0.4px',
      backgroundColor: color,
      opacity: 0.85,
    },

    '&::before': {
      transform: 'translate(-50%, -50%) rotate(90deg)',
    },
  }),
);

const Text = styled(Typography, { label: 'Text' })<{ type: 'dimmed' | 'normal' | 'error' }>(({ type }) => ({
  position: 'relative',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '22px',
  fontFamily: type === 'normal' ? 'Yonit-Medium' : 'Yonit-Reqular',
  color: type !== 'error' ? '#ffffff' : '#FFADAF',
  opacity: type === 'dimmed' ? 0.5 : 1,
}));

const Progress = styled(
  ({ value, className = '' }: { value: number; className?: string }) => (
    <div className={className}>
      <div className="value" style={{ width: `${value}%` }} />
    </div>
  ),
  { label: 'Progress' },
)(() => ({
  position: 'relative',
  backgroundColor: '#F5F5F5',
  borderRadius: '1px',
  width: '88px',
  height: '2px',
  margin: '1.8rem 0 0',

  '& .value': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: '#2F80ED',
    borderRadius: '1px',
  },
}));

const CrossBtn = styled('button', { label: 'CrossBtn' })(() => ({
  position: 'absolute',
  pointerEvents: 'visiblePainted',
  zIndex: 2,
  top: '-0.6rem',
  left: '-0.6rem',
  width: '2.0rem',
  height: '2.0rem',
  padding: 0,
  border: '1px solid #868E96',
  borderRadius: '50%',
  backgroundColor: '#D9D9D9',
  cursor: 'pointer',
  outline: 'none',

  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: '60%',
    height: '0.1rem',
    backgroundColor: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },

  '&::after': {
    transform: 'translate(-50%, -50%) rotate(-45deg)',
  },
}));

interface Props {
  index: number;
  isError: boolean;
  slotData: { state: SlotState; url?: string };
  onUploadComplete: (index: number, url: string) => void;
  onTotalSizeChange: (index: number, size: number) => void;
  onRemove: (index: number) => void;
  isSizeLimitOverflow: boolean;
}

export type SlotState = 'default' | 'uploading' | 'success' | 'error';

const MB = 1024 * 1024;
const SIZE_LIMIT = 5 * MB;

const PhotoUploader = (props: Props) => {
  const { index, slotData, isError, onUploadComplete, onTotalSizeChange, onRemove, isSizeLimitOverflow } = props;
  const [currentState, setCurrentState] = React.useState<SlotState>(slotData.state);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState('');
  const { config } = useAppState();
  const { clientForm: texts } = config.texts;

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.size > SIZE_LIMIT) {
        e.target.value = '';
        setCurrentState('error');
        setErrorMsg(texts.photoUploader.errorText);
        return;
      }

      setCurrentState('uploading');

      const result = await uploadPhoto({ photo: file }, (progress) => {
        setUploadProgress(progress);
      });

      if (result.success && result.data.url && !isSizeLimitOverflow) {
        setCurrentState('success');
        onUploadComplete(index, result.data.url);
        onTotalSizeChange(index, file.size);
      } else {
        setCurrentState('error');
        setErrorMsg(texts.photoUploader.errorText);
      }
    }
  };

  const renderCurrentState = () => {
    switch (currentState) {
      case 'default': {
        return (
          <>
            <PlusIcon color="#fff" type="normal" />
            <Text type="normal">{texts.photoUploader.uploadText}</Text>
          </>
        );
      }

      case 'uploading': {
        return (
          <>
            <Text type="normal">{texts.photoUploader.uploadingText}</Text>
            <Progress value={uploadProgress} />
          </>
        );
      }

      case 'success': {
        return (
          <>
            <CrossBtn onClick={() => onRemove(index)} type="button" />
            <Box
              sx={{
                position: 'absolute',
                left: '1rem',
                top: '1rem',
                right: '1rem',
                bottom: '1rem',
                backgroundColor: '#fff',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url(${slotData.url})`,
              }}
            />
          </>
        );
      }

      case 'error': {
        return (
          <>
            <Text type="error">{errorMsg}</Text>
            <PlusIcon className="plus-icon" color="#fff" type="error" />
            <Text type="dimmed" sx={{ color: '#fff' }}>
              {texts.photoUploader.tryAgainText}
            </Text>
          </>
        );
      }

      default:
        return null;
    }
  };

  React.useEffect(() => {
    setCurrentState(slotData.state);
  }, [slotData.state]);

  return (
    <Container isError={isError} state={currentState} htmlFor={`photo-uploader-${index}`}>
      {renderCurrentState()}
      <input id={`photo-uploader-${index}`} type="file" accept="image/*" onChange={onFileChange} />
    </Container>
  );
};

export default PhotoUploader;
