import React from 'react';
import { useField } from 'formik';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useAppState } from 'src/Context';
import PhotoUploaderSlot, { SlotState } from './PhotoUploaderSlot';
import Label from 'src/components/form/Label';
import Error from 'src/components/form/Error';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: '10px',
  marginBottom: '17px',
  marginTop: '-2px',
}));

const LabelWrapper = styled(Box, { label: 'LabelWrapper' })(() => ({
  width: '100%',
  height: '22px',
}));

const Disclaimer = styled(Box, { label: 'Disclaimer', shouldForwardProp: (prop) => prop !== 'singleLine' })<{
  singleLine: boolean;
}>(({ singleLine }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    marginTop: '10px',
  },
  ...(singleLine && {
    width: '100%',
    height: 'auto',
    marginTop: '-0.5rem',
    '@media (max-width: 600px)': {
      marginTop: '10px',
    },
  }),
}));

export interface SlotData {
  state: SlotState;
  url?: string;
}

interface Props {
  maxAmount: number;
}

const MB = 1024 * 1024;
const SIZE_LIMIT = 5 * MB;

const PhotoUploader = (props: Props) => {
  const { maxAmount } = props;
  const { config } = useAppState();
  const { clientForm: texts } = config.texts;
  const isMobile = useMediaQuery('(max-width: 600px)');
  const disclaimerSingleLineValue = isMobile ? 1 : 2;
  const [field, meta, helpers] = useField('photos');
  const [slots, setSlots] = React.useState<SlotData[]>([...field.value]);
  const [totalFilesSize, setTotalFilesSize] = React.useState({});
  const isErrorVisible = meta.touched && Boolean(meta.error);

  const onTotalSizeChange = (index: number, size: number) => {
    setTotalFilesSize((prevState) => ({ ...prevState, ...{ [index]: size } }));
  };

  const isSizeLimitOverflow = React.useMemo(() => {
    // @ts-expect-error this type is obviously a number
    const totalSize: number = Object.values(totalFilesSize).reduce((total, current) => {
      // @ts-expect-error this type is obviously a number
      const size = total + current;
      return size;
    }, 0);

    return totalSize > SIZE_LIMIT;
  }, [totalFilesSize]);

  const onUploadComplete = (index: number, url: string) => {
    const newSlots = [...slots];

    newSlots[index] = { state: 'success', url };
    if (newSlots.length < maxAmount) {
      newSlots.push({ state: 'default', url: '' });
    }

    setSlots(newSlots);

    helpers.setTouched(true);
    helpers.setValue(newSlots);
  };

  const onRemove = (index: number) => {
    const newSlots = [...slots];

    newSlots.splice(index, 1);
    if (newSlots.length < maxAmount && newSlots.at(-1)?.state !== 'default') {
      newSlots.push({ state: 'default', url: '' });
    }
    setSlots(newSlots);
    helpers.setValue(newSlots);
  };

  return (
    <Container>
      <LabelWrapper>
        <Label>{texts.stepOne.fields.photo.label}</Label>
      </LabelWrapper>

      {slots.map((slot, index) => (
        <PhotoUploaderSlot
          key={index}
          index={index}
          isError={isErrorVisible}
          slotData={slot}
          onUploadComplete={onUploadComplete}
          onRemove={onRemove}
          onTotalSizeChange={onTotalSizeChange}
          isSizeLimitOverflow={isSizeLimitOverflow}
        />
      ))}
      <Box sx={{ position: 'relative', marginTop: '-10px' }}>
        {isErrorVisible && <Error error={meta.error as string | string[]} />}
      </Box>
      <Disclaimer singleLine={slots.length > disclaimerSingleLineValue}>
        <Typography sx={{ fontSize: '14px', lineHeight: '15.33px' }} color={isSizeLimitOverflow ? 'red' : '#fff'}>
          *{texts.stepOne.fields.photo.photoRestriction}
        </Typography>
      </Disclaimer>
    </Container>
  );
};

export default PhotoUploader;
