import React from 'react';
import _ from 'lodash';
import styled from '@mui/material/styles/styled';
import Typography, { TypographyOwnProps } from '@mui/material/Typography';

const Label = styled(
  (props: TypographyOwnProps) => {
    const ommitedProps = _.omit(props, ['children']);
    return (
      <Typography {...ommitedProps} dangerouslySetInnerHTML={{ __html: props.children as string }} component="label" />
    );
  },
  { label: 'Label' },
)(() => ({
  fontSize: '16px',
  lineHeight: '17.52px',
  marginBottom: '4px',
  color: '#FFF',
  fontFamily: 'Yonit-Bold',
  '& span': {
    fontSize: '14px',
    lineHeight: '15.33px',
    color: `rgba(255,255,255,0.7)`,
  },
}));

export default Label;
