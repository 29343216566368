import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import logo from './assets/logo.svg';

import { useAppState } from 'src/Context';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '22px',
  '@media (max-width: 767px)': {
    paddingTop: '52px',
  },
}));

const Logo = styled('img', { label: 'Logo' })(() => ({
  display: 'block',
  width: '100%',
  maxWidth: '234.21px',
  marginBottom: '16px',
}));

const StepThree = () => {
  const { config } = useAppState();
  const { clientForm: texts } = config.texts;
  return (
    <Container>
      <Logo src={logo} />
      <Typography
        sx={{
          display: 'block',
          fontFamily: 'Yonit-Medium',
          fontSize: '28px',
          lineHeight: '24px',
          textAlign: 'center',
          mt: '0px',
          mb: '20px',
        }}
        component="p"
      >
        {texts.final.title}
      </Typography>
      <Typography
        sx={{
          maxWidth: '460px',
          fontSize: '18px',
          lineHeight: '24px',
          textAlign: 'center',
        }}
        component="p"
        dangerouslySetInnerHTML={{ __html: texts.final.subTitle }}
      ></Typography>
    </Container>
  );
};

export default StepThree;
