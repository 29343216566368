import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { useField } from 'formik';

import Error from '../Error';

const Container = styled('div', { label: 'ButtonContainer' })(() => ({
  position: 'relative',
  display: 'flex',
  gap: '11px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginTop: '8px',
}));

const Input = styled(Checkbox, { label: 'Input', shouldForwardProp: (prop) => prop !== 'error' })<{ error?: boolean }>(
  ({ error }) => ({
    width: '16px',
    height: '16px',
    marginTop: '4px',

    '& input': {
      opacity: 0,
      zIndex: 20,
    },

    '& .MuiTouchRipple-root': {
      display: 'none',
    },

    '& svg': {
      position: 'relative',
      width: '22px',
      height: '22px',
      zIndex: 1,

      ...(error && {
        visibility: 'hidden',
      }),
    },

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      pointerEvents: 'none',
      left: '50%',
      top: '50%',
      zIndex: 0,
      width: '16px',
      height: '16px',
      backgroundColor: '#fff',
      borderRadius: '2px',
      transform: 'translate(-50%, -50%)',
      border: '0.1rem solid #467EE5',

      ...(error && {
        border: '0.1rem solid #EC0203',
        zIndex: 2,
      }),
    },
  }),
);

const Label = styled('label', { label: 'Label' })(() => ({
  display: 'block',
  cursor: 'pointer',
  paddingTop: '5px',
  fontSize: '14px',
  lineHeight: '18px',
}));

interface Props extends CheckboxProps {
  name: string;
  label: string;
}

const Button = (props: Props) => {
  const { label, ...inputProps } = props;
  const [, meta, helpers] = useField(props.name);
  const shouldRenderError = !!(meta.touched && meta.error);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.checked);
  };

  return (
    <Container>
      <Input id={`${props.name}-checkbox`} {...inputProps} onChange={onChange} error={shouldRenderError} />
      <Box sx={{ flexGrow: 1 }}>
        <Label htmlFor={`${props.name}-checkbox`}>{label}</Label>
        {shouldRenderError && <Error error={meta.error as string | string[]} />}
      </Box>
    </Container>
  );
};

export default Button;
