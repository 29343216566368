import React from 'react';
import { useField } from 'formik';
import DOMPurify from 'dompurify';
import styled from '@mui/material/styles/styled';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

import Label from '../Label';
import Error from '../Error';

const Input = styled(OutlinedInput, { label: 'Input' })<OutlinedInputProps>(({ error }) => ({
  fontSize: '16px',
  lineHeight: '17.52px',
  color: '#343A40',
  backgroundColor: '#fff',
  borderRadius: '5px',
  padding: '5px 10px',
  minHeight: '38px',
  border: '1px solid #063682',

  ...(error && {
    border: '1px solid #FF4D4F',
  }),

  '& input': {
    padding: 0,

    '&:-webkit-autofill': {
      color: '#343A40 !important',
      WebkitBoxShadow: 'none',
      WebkitTextFillColor: '#343A40',
      backgroundColor: '#fff !important',
    },
  },

  '& fieldset': {
    border: 'none',
  },

  '&.MuiInputBase-multiline': {
    padding: '1.6rem 1.2rem 1.6rem 0',

    '& textarea': {
      height: '71px !important',
      paddingLeft: '1.2rem',
    },
  },
}));

const MaxChars = styled('p', { label: 'MaxChars', shouldForwardProp: (prop) => prop !== 'maxChars' })(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  fontSize: '12px',
  lineHeight: '13.14px',
  color: '#fff',
  marginTop: '3px',
  fontFamily: 'Open Sans',
}));

interface Props extends OutlinedInputProps {
  name: string;
  maxChars?: number;
  inputRef?: React.Ref<HTMLInputElement>;
}

const TextField = (props: Props) => {
  const { maxChars, ...inputProps } = props;
  const [field, meta, helpers] = useField(props.name);
  const shouldRenderError = !!(meta.touched && meta.error);

  const processTextChange = (text: string) => {
    if (maxChars && text.length > maxChars) {
      helpers.setValue(text.slice(0, maxChars));
      return;
    }

    helpers.setValue(text);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // check if words limit is already exceeded:
      if (maxChars && field.value.length >= maxChars) {
        e.preventDefault();
        return;
      }
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = DOMPurify.sanitize(e.target.value);
    processTextChange(value);
  };

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    // check if words limit is already exceeded:
    if (maxChars && field.value.length > maxChars) {
      e.preventDefault();
      return;
    }
  };

  return (
    <FormControl sx={{ mb: '19px' }} variant="outlined" fullWidth>
      <Label>{props.label}</Label>
      <Input
        id={props.name}
        {...field}
        {...inputProps}
        inputRef={props.inputRef}
        error={shouldRenderError}
        autoComplete="off"
        onKeyDown={onKeyDown}
        onChange={onChange}
        onPaste={onPaste}
      />
      <Box sx={{ position: 'relative' }}>
        {shouldRenderError && <Error error={meta.error as string | string[]} />}
        {maxChars && (
          <MaxChars>
            {field.value.length}/{maxChars} תווים
          </MaxChars>
        )}
      </Box>
    </FormControl>
  );
};

export default TextField;
