import React, { useEffect, useState } from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';

import { theme } from 'src/theme';
import { AppContextProvider } from 'src/Context';
import FormSteps from 'src/components/FormSteps';

export const App = () => {
  const [isBuiltIn, setIsBuiltIn] = useState<boolean>(false);

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const builtin = params.get('builtin');
    setIsBuiltIn(!!builtin);

    if (!!builtin) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
  }, []);

  return (
    <ThemeProvider theme={theme(isBuiltIn)}>
      <CssBaseline />
      <AppContextProvider>
        <FormSteps />
      </AppContextProvider>
    </ThemeProvider>
  );
};
