import React from 'react';
import styled from '@mui/material/styles/styled';

const Container = styled('div', { label: 'ButtonContainer' })(() => ({
  position: 'relative',
  width: '100%',
  height: '40px',
  maxWidth: '406px',
  margin: '0px auto',
}));

const StyledButton = styled('button', { label: 'Button' })<{ primary: boolean; error?: string }>(
  ({ primary, error }) => ({
    display: 'flex',
    cursor: 'pointer',
    fontFamily: 'Yonit-Medium',
    fontSize: '18px',
    lineHeight: '19.71px',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0',
    borderRadius: '0',
    padding: '0 1.5rem',
    transition: 'all ease 0.3s',
    ...(primary
      ? {
          color: '#fff',
          backgroundColor: '#337BE7',
        }
      : {
          color: '#495057',
          backgroundColor: '#F5F5F5',
        }),
    ...(error && {
      border: '0.1rem solid #EC0203',
    }),
    '&:hover': {
      backgroundColor: '#7DB1FF',
    },
  }),
);

const Error = styled('div', { label: 'ButtonError' })(() => ({
  position: 'absolute',
  width: '150%',
  bottom: '100%',
  left: '50%',
  transform: 'translate(-50%, -0.7rem)',
  fontSize: '1.3rem',
  lineHeight: '1.42rem',
  color: '#fff',
  textAlign: 'center',

  span: {
    backgroundColor: '#EC0203',
    padding: '0.1rem 1rem',
    margin: '0',
  },
}));

interface Props {
  label: string;
  primary?: boolean;
  error?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
}

const Button = (props: Props) => {
  const { label, error, onClick, primary = false, type = 'button' } = props;

  return (
    <Container>
      <StyledButton primary={primary} error={error} type={type} onClick={onClick}>
        {label}
      </StyledButton>
      {error && (
        <Error>
          <span>{error}</span>
        </Error>
      )}
    </Container>
  );
};

export default Button;
