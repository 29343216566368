import React from 'react';
import * as yup from 'yup';
import _ from 'lodash';
import { Form, useFormik, FormikProvider } from 'formik';

import MuiTextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { TextField, Button, Checkbox, Autocomplete } from 'src/components/form';
import { useAppDispatch, useAppState } from 'src/Context';

import PhotoUploader, { SlotData } from './PhotoUploader';

import logo from './assets/logo.svg';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
  marginBottom: '51px',
}));

const Logo = styled('img', { label: 'Logo' })(() => ({
  display: 'block',
  width: '235px',
  marginBottom: '20px',
}));

const ButtonsContainer = styled(Box, { label: 'ButtonsContainer' })(({ theme }) => ({
  display: 'flex',
  width: '167px',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  margin: '15px auto 0',
}));

export interface FormValues {
  name: string;
  email: string;
  person: {
    value: string;
    title: string;
  } | null;
  story: string;
  photos: SlotData[];
  confirmation: boolean;
}

interface Props {
  onNext: () => void;
}

const StepOne = (props: Props) => {
  const { config, form, people } = useAppState();
  const dispatch = useAppDispatch();

  const { clientForm: texts } = config.texts;
  const options = people.map((p) => ({
    value: p.id,
    title: `${p.b} ${p.c}${p.displayCityInSearch ? ` (${p.f})` : ''}`,
  }));
  const sortedOptions = _.orderBy(options, 'title');
  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      name: yup.string().test('validator', function (input) {
        if (input) {
          const spitedName = input.split(' ');

          if (input.trim() === '') {
            return this.createError({ path: this.path, message: texts.validation.stepOne.senderName.required });
          } else if (spitedName.length < 2 || spitedName.some((name) => name.length < 2)) {
            return this.createError({ path: this.path, message: texts.validation.stepOne.senderName.invalidValue });
          }
          return true;
        }
        return this.createError({ path: this.path, message: texts.validation.stepOne.senderName.required });
      }),
      email: yup
        .string()
        .email(texts.validation.stepOne.email.invalidValue)
        .required(texts.validation.stepOne.email.required),
      person: yup.object().shape({}).required(texts.validation.stepOne.person.required),
      photos: yup
        .array()
        .of(
          yup.object().shape({
            state: yup.string(),
            url: yup.string(),
          }),
        )
        .test('min', texts.validation.stepOne.story.required, (value) => {
          const notEmptyItems = value?.filter((item) => Boolean(item.url) !== false) ?? [];
          return notEmptyItems.length !== 0;
        })
        .required(texts.validation.stepOne.story.required),
      story: yup.string().required(texts.validation.stepOne.story.required),
      confirmation: yup.bool().oneOf([true], texts.validation.stepOne.confirmation.required),
    });
  }, [config]);
  const formikContext = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: form.stepOne,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({ type: 'UPDATE_FORM_DATA', payload: { target: 'stepOne', data: { ...values } } });
      props.onNext();
    },
  });

  return (
    <Container>
      <Logo src={logo} />
      <Typography
        variant="stepSubTitle"
        mb="16px"
        component="p"
        fontSize="18px"
        lineHeight="24px"
        dangerouslySetInnerHTML={{ __html: texts.stepOne.subTitle }}
      ></Typography>
      <Box>
        <FormikProvider value={formikContext}>
          <Form>
            <Autocomplete
              name="person"
              label={texts.stepOne.fields.personName.label}
              defaultValue={form.stepOne.person}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={sortedOptions}
              isVirtualList
              getOptionLabel={(option) => (option as (typeof sortedOptions)[number]).title}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.title}
                </li>
              )}
              renderInput={(params) => <MuiTextField {...params} placeholder={''} />}
            />

            <TextField name="name" label={texts.stepOne.fields.name.label} placeholder={''} />
            <TextField name="email" label={texts.stepOne.fields.email.label} placeholder={''} />

            <PhotoUploader maxAmount={1} />

            <TextField
              name="story"
              multiline
              fullWidth
              rows={4}
              maxChars={130}
              label={texts.stepOne.fields.story.label}
              placeholder={''}
            />

            <Checkbox
              name="confirmation"
              label={texts.stepOne.fields.confirmation.label}
              defaultChecked={form.stepOne.confirmation}
            />

            <ButtonsContainer>
              <Button type="submit" primary label={texts.sendBtn} />
            </ButtonsContainer>
          </Form>
        </FormikProvider>
      </Box>
    </Container>
  );
};

export default StepOne;
