export const analytics = {
  gtag: {
    event: (action: string, params?: Record<string, string>) => {
      // @ts-expect-error gtag is added in html.head:
      const { gtag } = window;

      if (gtag) {
        if (params) {
          console.log('gtag:', action, params);
          gtag('event', action, params);
        } else {
          console.log('gtag:', action);
          gtag('event', action);
        }
      } else {
        console.log('%cgtag is undefined', 'color: red');
      }
    },
  },
};
